import React from 'react'

const logo = () => (
	<svg width="30" height="30" viewBox="0 0 598 598">
		<g fill="#064D85">
			<path
				d="M 298.92,-0.43
           C 298.92,-0.43 298.92,141.10 298.92,141.10
             298.92,141.10 296.01,144.12 296.01,144.12
             296.01,144.12 242.88,92.84 242.88,92.84
             242.88,92.84 83.24,91.06 83.24,91.06
             83.24,91.06 185.10,189.38 185.10,189.38
             185.10,189.38 115.43,189.20 115.43,189.20
             115.43,189.20 -0.92,298.45 -0.92,298.45
             -0.92,298.45 140.69,298.82 140.69,298.82
             140.69,298.82 144.12,302.13 144.12,302.13
             144.12,302.13 92.55,355.78 92.55,355.78
             92.55,355.78 91.19,515.33 91.19,515.33
             91.19,515.33 189.30,413.26 189.30,413.26
             189.30,413.26 189.30,482.89 189.30,482.89
             189.30,482.89 298.92,598.89 298.92,598.89
             298.92,598.89 298.92,457.36 298.92,457.36
             298.92,457.36 301.83,454.34 301.83,454.34
             301.83,454.34 354.96,505.62 354.96,505.62
             354.96,505.62 514.59,507.40 514.59,507.40
             514.59,507.40 412.73,409.08 412.73,409.08
             412.73,409.08 482.40,409.26 482.40,409.26
             482.40,409.26 598.75,300.01 598.75,300.01
             598.75,300.01 457.14,299.64 457.14,299.64
             457.14,299.64 453.71,296.33 453.71,296.33
             453.71,296.33 505.28,242.68 505.28,242.68
             505.28,242.68 506.64,83.14 506.64,83.14
             506.64,83.14 408.53,185.20 408.53,185.20
             408.53,185.20 408.53,115.57 408.53,115.57
             408.53,115.57 298.92,-0.43 298.92,-0.43 Z
           M 298.92,181.08
           C 364.21,181.09 417.13,233.98 417.13,299.23
             417.13,299.23 417.13,299.23 417.13,299.23
             417.13,364.48 364.21,417.37 298.92,417.38
             298.92,417.38 298.92,417.38 298.92,417.38
             233.63,417.38 180.70,364.48 180.70,299.23
             180.70,299.23 180.70,299.23 180.70,299.23
             180.70,233.98 233.63,181.08 298.92,181.08
             298.92,181.08 298.92,181.08 298.92,181.08
             298.92,181.08 298.92,181.08 298.92,181.08 Z"
			/>
		</g>
	</svg>
)

export default logo
